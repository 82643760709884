body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.player__files {
	padding: 1em;
	background: rgba(255,255,255,.8);
	color: #000;
	margin: 1em 0 0 .5em;
	display: inline-block;
	transition: all .5s;
	position: relative;
	max-width:100%;
}

.player__files .l-wrapper {
	display: grid;
	transition: all .5s;
	position: relative;
	grid-template-areas: 'action' 'file';
	grid-template-columns: -webkit-fit-content fit-to-content;
	grid-template-columns: fit-content fit-to-content;

}

.player__action {
	display: inline-block;
	grid-area: action;
	grid-column: 1;
}

.player__file {
	display: inline-block;
	grid-area: file;
	grid-column: 2;
	grid-row:1;

}

.player__files button {
	background: #243949;
	color: #fff;
	border:none;
	padding: .75em 1.25em;
	margin: .5em .5em 0 0;
}
.player__files button:hover {
	cursor: pointer;
}

.player__name {
	font-size:1.25em;
	font-weight: 600;
	text-transform: uppercase;
}

.player__date {
	font-size: .75em;
	text-transform: uppercase;
	color:#243949;
}

.player__controls {
	width: 100%;
	text-align: center;
}

.player__controls audio {
	margin:auto;
}

h2 {
	text-align: center;
	font-weight: 900;
	background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);	-webkit-background-clip: text;
	-webkit-text-fill-color:transparent;
	color:#243949;
}

input {text-align: center;text-transform: uppercase;}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

